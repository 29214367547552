.code-block {
  margin: 0 auto;
  overflow: auto;
}
.hljs {
  max-width: 100vw;
}
.hljs-meta {
  color:#08203E;
}
.hljs-comment {
  color:#08203E;
  word-wrap: break-word;
}