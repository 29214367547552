 .root {
  background-image: radial-gradient(farthest-corner at 0 0, #ebf4f5 0%, #b5c6e0  100%);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.logo {
  margin: 0 auto 5vh auto;
  width: 50vw;
  pointer-events: none;
}
